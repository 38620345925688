import React from 'react';
import SliderProducts from './SliderProducts';

const SliderProductsItems = () => {
  const images = [
    '/images/products/acacia.png',
    '/images/products/buckwheat.png',
    '/images/products/linden.png',
    '/images/products/meadow.png',
  ];

  return (
    <div className="SliderProductsItems">
      <SliderProducts images={images} />
    </div>
  );
};

export default SliderProductsItems;
