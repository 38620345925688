import React, { Component } from "react";
import ReactDOM from "react-dom";
import ReactCardCarousel from "react-card-carousel";
import './Carousel.css'

class Carousel extends Component {
  static get CONTAINER_STYLE() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      return{
        position: "relative",
        width: "450px",
        height: "350px",
        display: "flex",
        flex: 1,
        backgroundImage: "url('./images/system/honey-header-pic.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "100% auto",
      }
    }
  return {
    position: "relative",
    width: "500px",
    height: "450px",
    display: "flex",
    flex: 1,
    overflow: "visible",
    backgroundImage: "url('./images/system/honey-header-pic.png')",
    backgroundRepeat: "repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  }
}

  static get ITEM_STYLE(){
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      return{
        position: "relative",
        width: "200px",
        height: "auto",
        display: "flex",
        flex: 1,
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }
    }
    if (screenWidth >= 768 && screenWidth <= 1024) {
      return{
        position: "relative",
        width: "250px",
        height: "auto",
        display: "flex",
        flex: 1,
        backgroundImage: "url('./images/system/honey-header-pic.png')",
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }
    }else{
      return{
        position: "relative",
        width: "300px",
        height: "auto",
        display: "flex",
        flex: 1,
        backgroundImage: "url('./images/system/honey-header-pic.png')",
        backgroundRepeat: "repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }
    }
  }
  render() {
    return (
      <div style={Carousel.CONTAINER_STYLE}>
        <ReactCardCarousel autoplay={true} autoplay_speed={3000} disable_box_shadow={true} >
          <div ><img style={Carousel.ITEM_STYLE} src="./images/animation/honey_anim_right.png"/></div>
          <div ><img style={Carousel.ITEM_STYLE} src="./images/animation/honey_anim_cen.png"/></div>
          <div ><img style={Carousel.ITEM_STYLE} src="./images/animation/honey_anim_left.png"/></div>
        </ReactCardCarousel>
      </div>
    );
  }
}

export default Carousel
