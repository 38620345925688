import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderProducts.css';


const SliderProducts = ({ images }) => {
  const [slidesToShow, setSlidesToShow] = useState(getSlidesToShow());
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderRef = useRef(null);

  useEffect(() => {
    function handleResize() {
      setSlidesToShow(getSlidesToShow());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getSlidesToShow() {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      return 1;
    } else if (windowWidth >= 768 && windowWidth <= 991) {
      return 2;
    } else {
      return 4;
    }
  }

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    sliderRef.current.slickPrev();
  };
  
  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    sliderRef.current.slickNext();
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    swipeToSlide: true, 
    arrows: false,
    centerPadding: "0px"
  };

  return (
    <div className='hits-mob'>
        <h2 className='f-40 t-color-C68800'> Хіти продажів</h2>
        <p>Обирайте з найпопулярніших сортів і насолоджуйтеся користю та солодкістю кожного дня.</p>
    
    <div className="hits-slider-container">
      <button onClick={handlePrevSlide} className="custom-arrow-products left">
        <div class="slider-btn-pulsation"></div>
        <img src='/images/system/ico/arrow-left.svg' alt="prev" />
      </button>
      <div className="hits-slider">
        <Slider ref={sliderRef} {...settings}>
        {images.map((image, index) => {
          const productName = image.split('/').pop().split('.')[0];
          let displayName;
          switch(productName){
            case 'acacia':
              displayName = 'Акацієвий мед';
            break;
            case 'buckwheat':
              displayName = 'BUCKWHEAT';
            break;
            case 'linden':
              displayName = 'LINDEN'
            break;
            case 'meadow':
              displayName = 'Meadow Honey';
            break;
          }
          return (
            <div key={index} className='hits-img-container'>
              <a href={`/${productName}`} className='product-link'>
                <div className='image-wrapper'>
                  <img src={image} alt={`Слайд ${index + 1}`} className='hits-img' />
                  <span className='product-name'>{displayName}</span>
                </div>
              </a>
              <a href={`/${productName}`} className='contact-us-btn buy'>Придбати</a>
              </div>
          );
        })}
        </Slider>
      </div>
      <button onClick={handleNextSlide} className="custom-arrow-products right">
        <div class="slider-btn-pulsation"></div>
        <img src='/images/system/ico/arrow-right.svg' alt="next" />
      </button>
    </div>
    </div>
  );
};

export default SliderProducts;
