import React from 'react';
import { Link } from 'react-router-dom';
import './Breadcrumbs.css';

const Breadcrumbs = ({ items }) => {
  return (
    <nav className="breadcrumbs">
    {items.map((item, index) => (
      <span key={index} className={`breadcrumb-item ${item.dimmed ? 'dimmed' : ''} ${item.active ? 'active' : ''}`}>
        {item.link ? (
          <Link to={item.link}>{item.label}</Link>
        ) : (
          <span>{item.label}</span>
        )}
        {index < items.length - 1 && " > "}
      </span>
    ))}
  </nav>
  );
};

export default Breadcrumbs;