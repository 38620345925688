import React, { useState, useEffect } from 'react';
import './../css/ProductPage.css';
import MenuToggle from '../function/menuToggle';
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs';
import { Link } from 'react-router-dom';

const MeadowHoneyPage = () => {
  const allImages = [
    './images/meadow/meadow_gen.webp',
    './images/meadow/meadow_2.webp',
    './images/meadow/meadow_3.webp',
    './images/meadow/meadow_4.webp'
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [mainImage, setMainImage] = useState(allImages[0]);
  const [visibleThumbnails, setVisibleThumbnails] = useState([]);

  useEffect(() => {
    setMainImage(allImages[currentIndex]);
  }, [currentIndex]);

  useEffect(() => {
    const numThumbnails = 3;
    const startIndex = Math.max(0, currentIndex - Math.floor(numThumbnails / 2));
    const endIndex = Math.min(allImages.length, startIndex + numThumbnails);

    const thumbnails = allImages.slice(
      Math.max(0, endIndex - numThumbnails),
      endIndex
    );

    setVisibleThumbnails(thumbnails);
  }, [currentIndex]);

  const handlePrevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? allImages.length - 1 : prevIndex - 1));
  };

  const handleNextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === allImages.length - 1 ? 0 : prevIndex + 1));
  };

  const handleThumbnailClick = (src) => {
    const index = allImages.indexOf(src);
    setCurrentIndex(index);
  };



const scrollToAnchor = (anchorId) => {
  localStorage.setItem('scrollToAnchor', anchorId);
  window.location.assign('/');
};

useEffect(() => {
  const anchorId = localStorage.getItem('scrollToAnchor');
  if (anchorId) {
      setTimeout(() => {
          const anchor = document.querySelector(anchorId);
          if (anchor) {
              window.scrollTo({
                  top: anchor.offsetTop,
                  behavior: 'smooth' 
              });
          }
          localStorage.removeItem('scrollToAnchor');
      }, 0);
  }
}, []);

  const breadcrumbs = [
    { label: 'Главная', link: '/', dimmed: true},
    { label: 'Meadow Honey', link: '/acacia', active: true}
  ];

  return (
    <>
      <div className='header' id='header'>
        <MenuToggle />
        <div className='menu-items'>
          <a href='/'><img src='./images/system/medove-logotype.png' alt='logo' className='logo'/></a>
          <div><Link to='/' className='f-15-l-20 t-color-C68800"'>Про нас</Link></div>
          <div><Link to='/' className='f-15-l-20 t-color-C68800"'>Український мед </Link></div>
          <div><Link to='/' className='f-15-l-20 t-color-C68800"'>Хіти продажів </Link></div>
          <div><Link to='/' className='f-15-l-20 t-color-C68800"'>Наші бджоли та вулики </Link></div>
          <div><Link to='/' className='f-15-l-20 t-color-C68800"'>Наш сервіс </Link></div>
          <div><Link to='/' className='f-15-l-20 t-color-C68800"'>Зв'яжіться з нами </Link></div>  
        </div>
      </div>
      <div className="container">
        <div className="product-page">
        <Breadcrumbs items={breadcrumbs} />
        <div className='product-content'>
        <div className="product-gallery">
            <div className="main-image-wrapper">
              <button onClick={handlePrevSlide} className="product-custom-arrow prec">
                <div className="slider-btn-pulsation"></div>
                <img src='/images/system/ico/arrow-left.svg' alt="prev" />
              </button>
              <img src={mainImage} alt="Акацієвий мед" className="gen-image" />
              <button onClick={handleNextSlide} className="product-custom-arrow follow">
                <div className="slider-btn-pulsation"></div>
                <img src='/images/system/ico/arrow-right.svg' alt="next" />
              </button>
            </div>
            <div className="thumbnails-wrapper">
              <div className="thumbnails">
                {visibleThumbnails.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`linden ${index + 1}`}
                    className={mainImage === img ? 'active' : ''}
                    onClick={() => handleThumbnailClick(img)}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="product-details">
            <h1>Meadow Honey</h1>
            <p className='product-details-item'>
              Мед натуральний з різнотрав'я
              Мед із різнотрав’я — унікальне поєднання насиченого аромату та смаку, зібраного з різних медоносних рослин.
            </p>
            <p className='product-details-item'>
              <strong>Склад:</strong> натуральний квітковий мед, зібраний із різнотрав'я. Вищий сорт. Без ГМО. Натуральний мед може кристалізуватися. Рекомендується зберігати в приміщеннях, захищених від прямих сонячних променів, при температурі не вище 25°C. Людям з алергією слід проконсультуватися з лікарем перед вживанням.
            </p>
            <p className='product-details-item'><strong>Вага:</strong> 400г</p>
            <p className='product-details-item'><strong>Поживна цінність на 100 г продукту:</strong></p>
            <ul>
              <li>жири – 0 г (г)</li>
              <li>вуглеводи - 80,3 г (г)</li>
              <li>з них цукри* - 74,6 г (г)</li>
              <li>*природний вміст цукрів</li>
              <li>білки - 0,8 г (г)</li>
              <li>сіль - 0 г (г)</li>
            </ul>
            <p className='product-details-item'><strong>Енергетична цінність (калорійність) на 100 г продукту:</strong> 380 кДж (кДж) / 324 ккал (ккал)</p>
            <p className='product-details-last-item'>Продукт сертифікований згідно з європейською системою контролю якості.</p>
            <a href="#medove-form" className="contact-us-btn anim-button"  onClick={() => scrollToAnchor('#medove-form')}>Зв'язатися з нами</a>
          </div>
        </div>
        </div>
      </div>
      <div className='footer-bg-color'>
        <div className='footer ml-mr-20 '>
          <img src='./images/system/medove-logotype.png' alt='logo' className='logo' />
          <div className='footer-item'>
            <h4 className='f-13-l-20 t-color-C68800'>MEDOVE</h4>
            <div><Link to='/' className='f-13-l-20 t-color-FFF'>Український мед </Link></div>
            <div><Link to='/' className='f-13-l-20 t-color-FFF'>Наші бджоли та вулики </Link></div>
            <div><Link to='/' className='f-13-l-20 t-color-FFF'>Наш сервіс </Link></div>
            <div><Link to='/' className='f-13-l-20 t-color-FFF'>Зв'яжіться з нами </Link></div>
          </div>
          <div className='footer-item call'>
            <div className='f-13-l-20 t-color-C68800 '>Зв'язок з нами</div>
            <p className='f-13-l-20 t-color-FFF'>Україна, м. Запоріжжя, вулиця Михайла Гончаренка, 26</p>
          </div>
          <div className='map footer-item'>
            <h4 className='f-13-l-20 t-color-C68800'>Карта проїзду</h4>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1338.916707756895!2d35.11564683898166!3d47.84282049301367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40dc60d32a8c7b2d%3A0x48ebb75342a377fd!2z0LLRg9C70LjRhtGPINCc0LjRhdCw0LnQu9CwINCT0L7QvdGH0LDRgNC10L3QutCwLCAyNiwg0JfQsNC_0L7RgNGW0LbQttGPLCDQl9Cw0L_QvtGA0ZbQt9GM0LrQsCDQvtCx0LvQsNGB0YLRjCwgNjkwMDA!5e0!3m2!1suk!2sua!4v1716273694470!5m2!1suk!2sua"></iframe>
          </div>
          <button className='arrow-top-btn'><a href='#header'><img src='./images/system/ico/arrow-top.svg' alt='arrow-top' /></a></button>
        </div>
      </div>
    </>
  );
};
  
export default MeadowHoneyPage;
