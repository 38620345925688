import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import MainPage from './pages/MainPage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AcaciaHoneyPage from './pages/AcaciaHoneyPage';
import BuckWheatPage from './pages/BuckWheatPage';
import LindenPage from './pages/LindenPage';
import MeadowHoneyPage from './pages/MeadowHoneyPage';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/acacia" element={<AcaciaHoneyPage />} />
        <Route path="/buckwheat" element={<BuckWheatPage />} />
        <Route path="/linden" element={<LindenPage />} />
        <Route path="/meadow" element={<MeadowHoneyPage />} />
      </Routes>
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
